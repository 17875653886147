import { Component, ReactNode } from 'react';

export interface ErrorBoundaryProps {
  renderError: (error: any, retry: () => void) => ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: any;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    hasError: false,
    error: null
  };

  componentDidCatch(error: any) {
    this.setState({ error, hasError: true });
    /* eslint-disable-next-line no-console */
    console.error('Caught an unexpected unknown error!', error);
  }

  retry = () => {
    this.setState({
      error: null,
      hasError: false
    });
  };

  render() {
    const {
      state: { hasError, error },
      props: { renderError, children },
      retry
    } = this;

    return hasError ? renderError(error, retry) : children;
  }
}
